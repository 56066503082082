.DominiosCrud {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.DominiosCrud .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.DominiosCrud .row .search input {
    width: 260px;
    height: 30px;
    
    outline: none;
    border: none;
    border-radius: 2px;
    padding: 0;
    padding-left: 6px;
    font-size: 18px;
}

.DominiosCrud .row .search button {
    margin-left: 10px;
}


.DominiosCrud .row table {
    width: 100%;
    
}

.DominiosCrud .row table thead {
    font-size: 20px;
}

.DominiosCrud .row table thead tr td {
    border-bottom: 1px solid white;
    padding: 0 0 4px 4px;
    color: var(--brand-color-green);
}

.DominiosCrud .row table tbody {
    font-size: 18px;
    
}

.DominiosCrud .row table tbody tr td{
    border-bottom: 1px solid white;
    padding: 4px 0 4px 4px;
    
    overflow-wrap: anywhere;
}

.DominiosCrud .row table tbody tr .actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.DominiosCrud .row table tbody tr .actions button {
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* margin: 0; */
    background-color: var(--brand-color-green);
    border: none;
    border-radius: 2px;
    padding: 3px 8px;
    font-size: 18px;
}

.DominiosCrud .row table tbody tr .actions button:hover {
    background-color: var(--brand-color-darkgreen);
}