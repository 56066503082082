.ClientesForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
}

.ClientesForm .Content {
    display: flex;
    flex-direction: column;
}

.ClientesForm .Content .row {
    width: 100%;
    margin-bottom: 20px;
}

.ClientesForm .row .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: rgba(124, 124, 124, 0.274);
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.ClientesForm .row .form .title {
    background-color: rgba(124, 124, 124, 0.274);
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
    margin: 0 0 40px 0;
    width: 100%;
    border-bottom: 1px solid white;
}

.ClientesForm .row .form .buttons .btn-consulta {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    border: 1px solid var(--brand-color-green);
    background-color: var(--brand-color-green);
    margin: 0 20px 20px 20px;
    font-size: 18px;
    width: 200px;
}

.ClientesForm .row .form .fields {
    display: flex;
    justify-content: space-between;
    
    flex-wrap: wrap;
    width: 80%;
}

.ClientesForm .row .form .fields .field{
    display: flex;
    flex-direction: column;
}

.ClientesForm .row .form .fields .erroCampo {
    border-bottom: 1px solid red;
}

.ClientesForm .row .form .fields input {
    font-size: 20px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    margin-bottom: 40px;
    width: 300px;
}

.ClientesForm .row .form .fields label {
    padding-left: 10px;
    margin-bottom: 10px;
    color: var(--brand-color-green);
}

.ClientesForm .row .form .fields2 input {
    width: 240px;
    margin-top: 80px;
}

.ClientesForm .row .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ClientesForm .row button {
    margin: 0 20px 20px 20px;
    font-size: 18px;
}