.Login {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

.Login .Content {
    display: flex;
    justify-content: center;
}

.Login .box {
    border-radius: 6px;
    border: none;
    background-color: var(--brand-color-green);
    box-shadow: 20px 20px 30px rgb(32, 32, 32);
 
    height: 500px;
    width: 460px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 799px) {
    .Login {
        margin-top: 20px;
    }

    .Login .box {
        height: 340px;
        width: 300px;
    }
}

.Login .box .login-form {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.Login .box .login-form .row{
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Login .box .login-form .row-left{
    display: flex;
    justify-content: flex-start;
}

.Login .box .login-form .row .login-input{
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid white;
    height: 30px;
    outline: none;
    font-size: 18px;
    width: 100%;
    letter-spacing: 1px;
}

.Login .box .login-form .row .login-input:focus~.login-label,
.Login .box .login-form .row .login-input:valid~.login-label {
    top:-20px;
    font-size: 16px;
}

.Login .box .login-form .row .login-label{
    position: absolute;
    top: 0;
    left: 0;
    
    font-size: 18px;
    pointer-events: none;
    
    transition: all .2s ease-out;
}

.Login .box .login-form .row .login-button {
    border: none;
    background-color:var(--brand-color-darkgreen);
    border-radius: 4px;
    color: white;
    outline: none;

    width: 50%;
    height: 200%;
}

.Login .box .login-form .row .login-button:active {
    background-color:#66720b;
}


.Login .box .options a {
    text-decoration: none;
    color: white;
}