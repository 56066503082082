.Header {
    display: flex;
    flex-direction: column;
    align-items: center; 
    grid-area: HEADER;
    
    background-color: whitesmoke;
    height: 140px;
    color: black;
}

.Header .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}

@media screen and (max-width: 799px) {

    .Header .row div {
        margin-left: 10px;
        font-size: 26px;
    }
    
    .Header .row img {
        width: 160px;
    }
}