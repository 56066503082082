.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: FOOTER;
    
    height: 40px;
    width: 100%;
    background-color: black;
}

@media screen and (max-width: 799px) {
    .Footer {
        font-size: 11px;
    }
}