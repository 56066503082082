.App {
  height: 100vh;
  display: grid;

  grid-template-rows: 140px auto 40px;
  
  grid-template-columns: auto;

  grid-template-areas: 
    'HEADER'
    'CONTENT'
    'FOOTER';
}