.Clientes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    ;
}

.Clientes .Content {
    display: flex;
    flex-direction: column;
}

.Clientes .row {
    width: 100%;
}