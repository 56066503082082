.TitleBar {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: rgba(124, 124, 124, 0.274);
}

.TitleBar .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;
    height: 120px;
}

.TitleBar .content .links img {
    width: 50px;
    margin-left: 30px;
}