.Cobrancas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

}

.Cobrancas .Content {
    display: flex;
    flex-direction: column;
}

.Cobrancas .row {
    width: 100%;
}