.CobrancasCrud {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.CobrancasCrud .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.CobrancasCrud .row .search-buttons button {
    margin-right: 10px;
}

.CobrancasCrud .row .search .form {
    display: flex;
    align-items: center;
}

.CobrancasCrud .row .search .form label {
    font-size: 20px;
    margin-right: 10px;
}

.CobrancasCrud .row .search .form select {
    width: 200px;
    background-color: transparent;
    color: white;
    outline: none;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 10px;
}

.CobrancasCrud .row .search .form select option {
    background-color: var(--brand-color-gray);
    color: white;
    padding-left: 10px;
}

.CobrancasCrud .row .search input {
    width: 100px;
    height: 30px;
    margin-right: 20px;
    outline: none;
    border: none;
    border-radius: 2px;
    padding: 0;
    padding-left: 6px;
    font-size: 18px;
}

.CobrancasCrud .row .search button {
    margin-left: 10px;
}

.CobrancasCrud .row table {
    width: 100%;
    background-color: var(--brand-color-darkgreen);
}

.CobrancasCrud .row table thead {
    background-color: var(--brand-color-green);
    font-size: 20px;
}

.CobrancasCrud .row table thead tr td {
    padding: 6px;
}

.CobrancasCrud .row table tbody {
    font-size: 18px;
    background-color: var(--brand-color-gray);
}

.CobrancasCrud .row table tbody tr td{
    padding: 6px;
}

.CobrancasCrud .row table tbody tr .actions {
    display: flex;
    justify-content: space-evenly;
}

.CobrancasCrud .row table tbody tr .actions button {
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: var(--brand-color-green);
    border: none;
    border-radius: 2px;
    padding: 3px 8px;
    font-size: 18px;
}

.CobrancasCrud .row table tbody tr .actions button:hover {
    background-color: var(--brand-color-darkgreen);
}