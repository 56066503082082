:root {
    --brand-color-green: #adc632;
    --brand-color-gray: #58585a;
    --brand-color-darkgreen: #7e8d0d;
    --brand-color-darkgreen2: #5d680a;
    --exit-color: rgb(192, 17, 52);
    --exit-color2: rgb(161, 15, 45);
    --font-xlarge: 32px;
    --font-large: 26px;
    --font-medium: 22px;
    --font-small: 18px;
}

.Container {
    display: flex;
    justify-content: center;

    width: 100%;
    max-width: 1300px;
    flex: 1;
}

.Content {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}
  
/* ===>>> Buttons <<<=== */
button {
    cursor: pointer;
}
.Btn {
    color: white;
    border: none;
    border-radius: 4px;
    width: 120px;
    height: 30px;
    font-size: var(--font-small);
}
.Btn-confirm {
    background-color: var(--brand-color-green);
}
.Btn-cancel {
    background-color: var(--exit-color);
}
.Btn-confirm:hover {
    background-color: var(--brand-color-darkgreen);
}
.Btn-cancel:hover {
    background-color: var(--exit-color2);
}
  
/* ===>>> Font size <<<=== */
.Font-xlarge {
    font-size: var(--font-xlarge);
}
.Font-large {
    font-size: var(--font-large);
}
.Font-medium {
    font-size: var(--font-medium); 
}
.Font-small {
    font-size: var(--font-small);
}
  
/* ===>>> Rows <<<=== */
.Row-exit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    margin-top: 80px;
    width: 100%;
}