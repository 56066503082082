.Grupos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.Grupos .Content {
    display: flex;
    flex-direction: column;
}

.Grupos .row {
    width: 100%;
}