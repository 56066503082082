.TipoServico select {
    width: 310px;
    background-color: transparent;
    color: white;
    outline: none;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 10px;
    margin-bottom: 40px;
}

.TipoServico select option {
    background-color: var(--brand-color-gray);
    color: white;
    padding-left: 10px;
}