.UsuariosForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.UsuariosForm .Content {
    display: flex;
    flex-direction: column;
}

.UsuariosForm .Content .row {
    width: 100%;
}

.UsuariosForm .row .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: rgba(124, 124, 124, 0.274);
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.UsuariosForm .row .form .title {
    background-color: rgba(124, 124, 124, 0.274);
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
    margin: 0 0 40px 0;
    width: 100%;
    border-bottom: 1px solid white;
}

.UsuariosForm .row .form .fields {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    
    width: 80%;
}

.UsuariosForm .row .form .fields .field{
    display: flex;
    flex-direction: column;
}

.UsuariosForm .row .form .fields input {
    font-size: 20px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    margin-bottom: 40px;
    width: 300px;
}

.UsuariosForm .row .form .fields label {
    padding-left: 10px;
    margin-bottom: 10px;
    color: var(--brand-color-green);
}

.UsuariosForm .row .form .fields input::placeholder {
    color: white;
}

.UsuariosForm .row .form .fields .erroCampo {
    border-bottom: 1px solid red;
}

/* .UsuariosForm .row select {
    width: 300px;
    background-color: transparent;
    color: white;
    outline: none;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 10px;
}

.UsuariosForm .row select option {
    background-color: var(--brand-color-gray);
    color: white;
    padding-left: 10px;
} */

.UsuariosForm .row .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.UsuariosForm .row button {
    margin: 0 20px 20px 20px;
    font-size: 18px;
}