.Home {
    grid-area: CONTENT;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    /* width: 100%; */
}

@media screen and (max-width: 799px) {
    .Home {
        width: auto;
    }

    .Home .Content .row-title {
        display: flex;
 
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .Home .Content .row-title .welcome {
        width: 100%;
        display: flex;
        justify-content: center;
    }


    .Home .Content .row-title .logout-button {
        display: flex;
        justify-content: center;
        align-items: center;       
        width: 100%;
    }

    .Home .Content .row a .card {
        height: 200px;
        width: 200px;
        margin: 20px 20px 0 20px;
        
    }

    .Home .Content .row-links {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    .Home .Content .row-links a img {
        margin: 0;
    }
}

.Home .Content {
    display: flex;
    flex-direction: column;
}

.Home .Content .row-title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    /* width: 100%; */
}

.Home .Content .row-title .welcome {
    font-size: 24px;
}

.Home .Content .row-title .Exit {
    margin-top: 20px;
}

.Home .Content .row {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    /* width: 100%; */

    margin-top: 40px;
}

.Home .Content .row .card {
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 6px;
    background-color: var(--brand-color-green);
    height: 260px;
    width: 260px;
    box-shadow: 20px 20px 30px rgb(32, 32, 32);
    font-size: 30px;
    margin-top:20px;
    margin-right: 20px;
    flex-wrap: wrap;
}

.Home .Content .row .card:hover {
    background-color: var(--brand-color-darkgreen);
    transition: all ease-in .1s;
}

.Home .Content .row a {
    text-decoration: none;
    color: white;
}

.Home .Content .row-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 80px;
}

.Home .Content .row-links img {
    width: 90px;
    margin-left: 30px;
}

