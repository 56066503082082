.ModalEnderecos {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff3;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.ModalEnderecos .container-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    height: auto;
    width: 60%;
    background-color: #000;
    border-radius: 6px;
}

.ModalEnderecos .container-modal .form-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    overflow: hidden;
}

.ModalEnderecos .container-modal .form-modal .title-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(124, 124, 124, 0.274);
    padding: 10px 0 10px 0;
    margin: 0 0 40px 0;
    width: 100%;
    border-bottom: 1px solid white;
}

.ModalEnderecos .container-modal .form-modal .fields-modal {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
    flex-wrap: wrap;
    width: 80%;
}

.ModalEnderecos .container-modal .form-modal .fields-modal .field-modal {
    display: flex;
    flex-direction: column;
}

.ModalEnderecos .container-modal .form-modal .fields-modal input {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    margin-bottom: 40px;
    width: 300px;
}

.ModalEnderecos .container-modal .form-modal .fields-modal label {
    padding-left: 10px;
    margin-bottom: 10px;
    color: var(--brand-color-green);
}

.ModalEnderecos .container-modal .form-modal .fields-modal .erroCampo {
    border-bottom: 1px solid red;
}

.ModalEnderecos .container-modal .form-modal .buttons-modal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ModalEnderecos .container-modal .form-modal .buttons-modal button {
    margin: 0 20px 20px 20px;
    font-size: 18px;
}