body {
  background: linear-gradient(-105deg, #666666, #444444, #222222) no-repeat fixed;
  
  color: white;
  
  margin: 0;
  padding: 0;

  font-family: Roboto;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}