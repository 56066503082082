.MenuBar {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: var(--brand-color-green);
}

.MenuBar .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.MenuBar .nav a {
    display: flex;
    justify-content: center;
    align-items: center;
    
    text-decoration: none;
    color: white;
    font-size: 24px;
    width: 100%;

}


@media screen and (max-width: 799px) {
    .Header {
        width: 100%;
    }
    
    .MenuBar {
        display: flex;

    }
    
    .MenuBar .nav a {
        font-size: 14px;
    }

}



